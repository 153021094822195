.state-image-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 200px;
}

.state-image-item img {
    height: 80px;
    object-fit: cover;
    box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
}