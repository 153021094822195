.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 50px;
}

.footer-col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    max-width: 33%;
}

.footer-col-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.footer .logo {
    width: 100%;
    object-fit: cover;
}

.socials {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.socials .icon {
    width: 50px;
}

.pages {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.pages a{
    text-decoration: none;
    color: black;
}

.contact p{
    margin: 0;
}

.madeBy {
    background-color: lightblue;
    width: 100%;
    text-align: center;
    padding: 20px 0;
}

.madeBy p {
    margin: 0;
}

@media screen and (max-width: 480px) {
    .footer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
        gap: 50px;
    }

    .footer-col {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        width: fit-content;
        max-width: 70%;
    }

    .footer-col-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        gap: 50px;
    }
}

@media screen and (max-width: 600px) {
    .slick-slide>div {
        margin: 0px;
    }
}

@media screen and (max-width: 1024px) {
    .slick-slide>div {
        margin: 0px;
    }
}
