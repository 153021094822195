.tournamentRegister {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.tournamentSystem {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.tournamentSystem img {
    width: 50%;
}

@media screen and (max-width: 480px) {
    .tournamentSystem img {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .tournamentSystem img {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .tournamentSystem img {
        width: 100%;
    }
}