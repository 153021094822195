.documentWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100vh;
    background-image: url('../../assets/images/Ohrid2.jpg');
    background-size: cover;
}

.documentHead {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: rgba(88, 165, 253, 0.2) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    width: 60%;
    color: white;
}

.documentHead img{
    width: 200px !important;
}

.downloadDocument {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 15px 20px;
    width: 60%;
    background: rgba(88, 165, 253, 0.2) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.downloadDocument a{
    padding: 15px 20px;
    color: white;
    text-decoration: none;
    background: rgba(88, 165, 253, 0.2) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

@media screen and (max-width: 480px) {
    .documentHead {
        width: 80%;
    }

    .documentHead h1{
        font-size: 20px;        
    }

    .documentHead img{
        width: 100px !important;
    }
    
    .downloadDocument {
        width: 80%;
        justify-content: center;
    }

    .downloadDocument h2{
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .documentHead {
        width: 80%;
    }

    .documentHead h1{
        font-size: 20px;        
    }

    .documentHead img{
        width: 100px !important;
    }
    
    .downloadDocument {
        width: 80%;
        justify-content: center;
    }

    .downloadDocument h2{
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {
    .documentHead {
        width: 80%;
    }

    .documentHead h1{
        font-size: 20px;        
    }

    .documentHead img{
        width: 100px !important;
    }
    
    .downloadDocument {
        width: 80%;
        justify-content: center;
    }

    .downloadDocument h2{
        font-size: 18px;
    }
}