.contactWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.contactWrapper .contact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.contactWrapper .contactMap {
    width: 100%;
}

.contactWrapper .contacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

@media screen and (max-width: 480px) {
    .contactWrapper .contacts {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .contactWrapper .contacts {
        width: 80%;
    }
}

@media screen and (max-width: 1024px) {
    .contactWrapper .contacts {
        width: 80%;
    }
}