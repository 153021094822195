.c-img {
    width: 100%;
}

.c-img img {
    object-fit: cover;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    height: 200px;
    width: 100%;
}

.card-holder {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.slick-slide>div {
    margin: 0 150px;
}

@media screen and (max-width: 480px) {
    .slick-slide>div {
        margin: 0px;
    }
}

@media screen and (max-width: 600px) {
    .slick-slide>div {
        margin: 0px;
    }
}

@media screen and (max-width: 1024px) {
    .slick-slide>div {
        margin: 0px;
    }
}

.card-details {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.button-t {
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    margin: 0 0 20px 0;
    background: rgba(255, 255, 255, 0.2) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}